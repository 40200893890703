import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loading = signal(false);
  public loadProgress = signal(0);

  setLoading(loading: boolean) {
    this.loading.set(loading);
  }

  setLoadProgress(progress: number) {
    this.loadProgress.set(progress);
  }
}
